import React from 'react';
import loadable from '@loadable/component';

import appSlider from 'assets/images/app-slider.mp4';

const FadeIn = loadable(() => import('../common/FadeIn'));

const AppFeatures = () => (
  <section className='app-fatures'>
    <div className='container'>
      <div className='app-fatures__grid'>
        <div className='app-fatures__content'>
          <h2 className='app-features__title'>Meet your pocket air quality wizard.</h2>
          <h3 className='app-features__subtitle for-desktop'>Clean air and smart settings, automagically delivered.</h3>
          <div className='app-fatures__description'>
            <p>Having a powerful air purifier in the room is great, but we knew that helping you understand what it’s *actually* doing would make it even better. Our app is packed with features to give you real-time insight, convenience, and control—so you can be the ultimate boss of your air.</p>
            <p className='for-desktop'>Mila can do so many genius things without needing to be told. Choose from a variety of set-it-and-forget-it settings within Automagic Mode, and enjoy air greatness on autopilot.</p>
          </div>
        </div>
        <div className='app-features__media'>
          <FadeIn>
            <video
              playsInline
              webkit-playsinline='true'
              autoPlay
              muted
              loop
            >
              <source src={appSlider} type='video/mp4' />
            </video>
          </FadeIn>
        </div>
        <h3 className='app-features__subtitle for-phone'>Clean air and smart settings, automagically delivered.</h3>
        <div className='app-fatures__description for-phone'>
          <p>Mila can do so many genius things without needing to be told. Choose from a variety of set-it-and-forget-it settings within Automagic Mode, and enjoy air greatness on autopilot.</p>
        </div>
      </div>
    </div>
  </section>
);

export default AppFeatures;
